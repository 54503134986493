import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { Colors } from '../utils/constants';
const arrowCss = css`
  font-size: 80px;
  :hover {
    color: ${Colors.EYA_RED};
  }
  @media screen and (max-width: 414px) {
    font-size: 40px;
  }
`;

export const LeftArrow = styled(IoIosArrowRoundBack)`
  margin-left: -16px;
  @media screen and (max-width: 414px) {
    margin-left: -8px;
  }
  ${arrowCss}
`

export const RightArrow = styled(IoIosArrowRoundForward)`
  margin-right: -16px;
  @media screen and (max-width: 414px) {
    margin-right: -8px;
  }
  ${arrowCss}
`
