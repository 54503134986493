import React from 'react';
import styled from 'styled-components';
import { LeftArrow, RightArrow } from './ArrowIcon';
import { Colors } from '../utils/constants';
import { Link } from 'gatsby';

const ArrowWithText = ({
  isLeftArrow,
  className,
  to,
  desc,
  title
}) => {
  const arrow = isLeftArrow ? <LeftArrow /> : <RightArrow />;
  return (
    <Link as="div" className={className} to={to}>
      {arrow}
      <div className="text-group">
        {title && <span className="title">{title}</span>}
        {desc && <span className="desc">{desc}</span>}
      </div>
    </Link>
  )
};

const StyledArrowWithText = styled(ArrowWithText)`

display: flex;
flex-direction: ${props => props.isLeftArrow ? 'row' : 'row-reverse'};
:hover {
  color: ${Colors.EYA_RED};
  .desc {
    color: #666;
  }
}
.title {
  align-self: ${props => props.isLeftArrow ? 'flex-start' : 'flex-end'};
}
.desc {
  color: #666;
  align-self: ${props => props.isLeftArrow ? 'flex-start' : 'flex-end'};
  @media screen and (max-width: 414px) {
    width: min-content;
  }
}

span {
  font-size: 25px;
  line-height: 1;
  @media screen and (max-width: 414px) {
    font-size: 18px;
  }
}

.text-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
img {
  margin-bottom: 0px;
}
`;

export const LeftArrowWithText = ({ref, ...RestProps}) => <StyledArrowWithText  isLeftArrow {...RestProps}/>
export const RightArrowWithText = ({ref, ...RestProps}) => <StyledArrowWithText {...RestProps}/>
