import React from 'react';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import styled from 'styled-components';
import { LeftArrowWithText, RightArrowWithText } from '../components/ArrowWithText';
import { Colors } from '../utils/constants';

const ArtistImg = styled(PreviewCompatibleImage)`
 img {
   margin-bottom: 0;
 }
`;

const ArtistTemplateWrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: 40% 1fr;
grid-gap: 32px;
@media screen and (max-width: 768px) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-container {
  width: 100%;
  flex-grow: 1;
}

.content-container {
  flex-basis: 100%;
  section {
    position: sticky;
    top: 16px;
    @media screen and (max-width: 768px) {
      position: unset;
    }  
  }


  .description {
    /* color: #666; */
    font-size: 24px;
    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }  
  }


}

.exhibitions {
  h4 {
    font-size: 18px;
  }
  p {
    color: #666;
  }
}

`;

export const ArtistTemplate = ({
  name,
  image,
  description,
  tags,
  exhibitions,
  artworks,
}) => {
  const imageList = artworks ? [image, ...artworks] : [image];
  const renderImgList = (images) => {
    return images.map((image, index) => (
      <ArtistImg key={index} imageInfo={image}/>
    ))
  }

  // dangerouslySetInnerHTML allows us write description in HTML 
  const renderExhibitions = (exhibitions) => {
    return exhibitions.map(({title, desc}, index) => (
      <section className="exhibitions" key={index}>
        <h4>{title}</h4>
        {/* <p>{desc}</p> */}
        <p className="html-text" dangerouslySetInnerHTML={{ __html: desc }} />
      </section>
    ));
  }
  const exhibts = exhibitions ? exhibitions : [];

  // dangerouslySetInnerHTML allows us write description in HTML 
  return (
    <ArtistTemplateWrapper>
      <div className="images-container">
        {renderImgList(imageList)}
      </div>
      <div className="content-container">
        <section>
          <p className="description html-text" dangerouslySetInnerHTML={{ __html: description }}>
          </p>
          {exhibts && exhibts.length > 0 && <h1>Exhibitions</h1>}
          {renderExhibitions(exhibts)}
        </section>
      </div>
    </ArtistTemplateWrapper>
  );
};


const Artist = (props) => {
  const artist = props.data.mdx.frontmatter;
  const { pageContext } = props;

  const renderPageControl = (prev, next) => (
    <div className="page-control">
      <div>
        {prev && (
          <LeftArrowWithText 
            to={`/artists/${prev.id}`}
            desc={prev.name}
            title={"Previous"}
          />
        )}
      </div>
      <div>
        {
          next && (
            <RightArrowWithText
              to={`/artists/${next.id}`}
              desc={next.name}
              title={"Next"}
            />
          )
        }
      </div>
    </div>
  )
  const seoDesc = `${artist.name} - ${artist.description}`;
  return (
    <PageLayout title={artist.name} className={props.className} seoDesc={seoDesc}>
      <ArtistTemplate
        name={artist.name}
        image={artist.image}
        description={artist.description}
        tags={artist.tags}
        exhibitions={artist.exhibitions}
        artworks={artist.artworks}
      />
      {renderPageControl(pageContext.prev, pageContext.next)}
    </PageLayout>
  )
}

export default styled(Artist)`
.page-control {
  margin-top: 16px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}
.html-text {
  a {
    color: ${Colors.EYA_RED};
  }
}

`;


export const query = graphql`
  query($id: String) {
    mdx(frontmatter: { id: {eq: $id }}) {
      frontmatter{
        name
        description
        tags {
          artistType
          tagsOfType
        }
        exhibitions {
          title
          desc
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        artworks {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
